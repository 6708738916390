import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/src/components/layouts/documentation.js";
import SEO from '../../components/seo';
import { Input } from 'godspeed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Input" description="hello description" mdxType="SEO" />
    <h1></h1>
    <h1>{`Input`}</h1>
    <h2>{`Inputs allow the user to enter information which will be used by the application.`}</h2>
    <h3>{`Commonly used types`}</h3>
    <ul>
      <li parentName="ul">{`Text`}</li>
      <li parentName="ul">{`Password`}</li>
      <li parentName="ul">{`Time`}</li>
      <li parentName="ul">{`Button`}</li>
    </ul>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Input }  from 'godspeed'
`}</code></pre>
    <br />
    <h1>{`Default Inputs`}</h1>
    <h2>{`These come out of the box, light with dark text.`}</h2>
    <p>{`The "placeholder" prop is required on all input instances.`}</p>
    <p>{`Use the psudo-element "::placeholder" to change the placeholder appearance.`}</p>
    <main>
  <section>
    <Input placeholder="Standard" mdxType="Input" />
  </section>
  <section>
    <Input placeholder="Standard" mdxType="Input" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Input placeholder="Standard" />
<Input placeholder="Standard" /> //::placeholder{color: steelblue;}
`}</code></pre>
    <br />
    <h1>{`Customized Inputs`}</h1>
    <p>{`You can pass the "shadow" prop to give the input a shadow.`}</p>
    <p>{`You can pass the "underlined" prop to remove the background and underline the input.`}</p>
    <p>{`You can pass the "autoFocus" prop to focus the input.`}</p>
    <main>
  <section>
    <Input placeholder="Shadow" shadow mdxType="Input" />
  </section>
  <section>
    <Input placeholder="Underlined" underlined mdxType="Input" />
  </section>
  <section>
    <Input placeholder="autoFocus" mdxType="Input" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Input placeholder="Shadow" shadow />
<Input placeholder="Underlined" underlined />
<Input placeholder="Focus" autoFocus={true} />
`}</code></pre>
    <br />
    <p>{`You can pass the "error" prop to show the input has an error.`}</p>
    <p>{`You can pass the "disabled" prop to disable the input.`}</p>
    <main>
  <section>
    <Input placeholder="Error" error mdxType="Input" />
  </section>
  <section>
    <Input placeholder="Disabled" disabled mdxType="Input" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Input placeholder="Error" error={true} /> 
<Input placeholder="Disabled" disabled={true} /> 
`}</code></pre>
    <br />
    <h1>{`Underlined Inputs`}</h1>
    <p>{`You can pass multiple props.`}</p>
    <main>
  <section>
    <Input placeholder="Underlined" underlined mdxType="Input" />
  </section>
  <section>
    <Input placeholder="Underlined with Shadow" underlined shadow mdxType="Input" />
  </section>
  <section>
    <Input placeholder="Underlined with Error" underlined error mdxType="Input" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Input placeholder="Underlined" underlined />
<Input placeholder="Underlined with Shadow" underlined shadow/>
<Input placeholder="Underlined with Error" underlined error={true}/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      